<template>
  <v-row justify="end" class="mr-4">
    <v-dialog v-model="isVisibleDialog" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on">
          <v-icon color="white" class="mr-2"> mdi-swap-horizontal </v-icon>
          {{ $t("generate_transfer") }}
        </v-btn>
      </template>
      <v-form ref="form" v-model="is_valid" lazy-validation>
        <v-card>
          <v-card-title class="pb-4">
            <span class="text-h3">{{ $t("transfer") }}</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container>
              <v-card elevation="5">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-autocomplete
                        dense
                        outlined
                        :label="$t('from_hub_*')"
                        v-model="editedTransfer.from_hub_id"
                        :items="listHubs"
                        item-text="name"
                        item-value="id"
                        @focus="getHubs"
                        :rules="[rules.required]"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-autocomplete
                        dense
                        outlined
                        :label="$t('to_hub_*')"
                        v-model="editedTransfer.to_hub_id"
                        :items="
                          listHubs.filter((item) => {
                            return item.id != editedTransfer.from_hub_id;
                          })
                        "
                        item-text="name"
                        item-value="id"
                        @focus="getHubs"
                        :rules="[rules.required]"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>

            <v-container>
              <v-card elevation="5">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-autocomplete
                        dense
                        autofocus
                        hide-details
                        outlined
                        :label="$t('name')"
                        v-model="transferProductToAdd.id"
                        :items="listProducts"
                        item-text="name"
                        item-value="id"
                        :search-input.sync="search_product_input"
                        :loading="isLoadingProducts"
                        no-filter
                        @keyup.enter="addProductByEnterKey"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                        dense
                        hide-details="auto"
                        outlined
                        label="Quantity"
                        type="number"
                        v-model="transferProductToAdd.qte_in_transfer"
                        :rules="[rules.quantity]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <v-btn
                        block
                        outlined
                        color="primary"
                        @click="addProduct(transferProductToAdd)"
                        :disabled="!is_valid"
                        >{{ $t("add") }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>

            <v-container>
              <v-card elevation="5">
                <v-card-text>
                  <div v-if="transferProducts">
                    <v-data-table
                      :headers="transferProductsHeaders"
                      :items="transferProducts"
                      hide-default-footer
                      dense
                      :items-per-page="-1"
                    >
                      <template v-slot:item.qte_in_transfer="{ item }">
                        <v-text-field
                          class="my-1"
                          outlined
                          dense
                          hide-details="auto"
                          type="number"
                          v-model="item.qte_in_transfer"
                          :rules="[
                            rules.min_quantity(0),
                            rules.max_quantity(getQteInHub(item)),
                          ]"
                        ></v-text-field>
                        <!-- ,rules.max_quantity(item.qte) -->
                      </template>
                      <template v-slot:item.total="{ item }">
                        {{ calcTotal(item) }}
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-icon color="red" @click="removeProduct(item)">
                          mdi-close
                        </v-icon>
                      </template>
                      <template v-slot:no-data>
                        {{ $t("empty") }}
                      </template>
                    </v-data-table>
                  </div>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="closeForm()">
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="saveForm(editedTransfer)"
              :disabled="!is_valid"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],

  computed: {
    ...mapGetters({
      isLoadingProducts: "products/isLoadingProducts",
      listProducts: "products/listProducts",
      paymentMethods: "paymentMethods/listMethods",
      listHubs: "hubs/activeHubs",
      productsQueryParams: "products/params",
    }),

    isVisibleDialog: {
      get() {
        return this.$store.state.transferProducts.isVisibleDialog;
      },
      set(value) {
        this.$store.commit("transferProducts/IS_VISIBLE_DIALOG", value);
      },
    },

    editedTransfer: {
      get() {
        return this.$store.state.transferProducts.editedTransfer;
      },
      set(value) {
        this.$store.commit("transferProducts/EDITED_TRANSFER", value);
      },
    },

    transferProducts: {
      get() {
        return this.$store.state.transferProducts.editedTransfer.products || [];
      },
      set(value) {
        this.$store.commit("transferProducts/EDITED_PRODUCTS", value);
      },
    },

    from_hub_id() {
      return this.editedTransfer.from_hub_id;
    },
    to_hub_id() {
      return this.editedTransfer.to_hub_id;
    },
  },

  watch: {
    from_hub_id: function (id) {
      this.$store.dispatch("transferProducts/getAlertProducts", {
        from_hub_id: id,
        to_hub_id: this.to_hub_id,
      });
    },
    to_hub_id: function (id) {
      this.$store.dispatch("transferProducts/getAlertProducts", {
        to_hub_id: id,
        from_hub_id: this.from_hub_id,
      });
    },

    search_product_input: function (search) {
      if (this.isLoadingProducts) return;

      this.$store.dispatch("products/leaf", {
        hub_id: this.from_hub_id,
        itemsPerPage: 20,
        search,
        for: "transfer",
      });
    },
  },

  data() {
    return {
      is_valid: true,
      search_product_input: "",
      transferProductToAdd: {
        qte_in_transfer: 1,
      },

      transferProductsHeaders: [
        {
          text: "name",
          align: "start",
          sortable: false,
          value: "name",
        },

        {
          text: "Total",
          value: "total",
          align: "center",
          sortable: false,
          width: "15%",
        },

        {
          text: "Quantity",
          value: "qte_in_transfer",
          align: "center",
          sortable: false,
          width: "20%",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
          width: "15%",
        },
      ],
    };
  },

  methods: {
    closeForm() {
      this.$store.dispatch("transferProducts/closeForm");
      this.$store.dispatch("products/list", this.productsQueryParams);
    },

    async saveForm(transfer) {
      if (this.$refs.form.validate()) {
        try {
          if (transfer.id) {
            await this.$store.dispatch("transferProducts/update", transfer);
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
          } else {
            await this.$store.dispatch("transferProducts/add", transfer);
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
          }
          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
      }
    },

    addProduct(transferProduct) {
      if (this.$refs.form.validate()) {
        const oProduct = this.transferProducts.find(
          (item) => item.id === transferProduct.id
        );
        if (oProduct) {
          oProduct.qte_in_transfer =
            Number(oProduct.qte_in_transfer) +
            Number(transferProduct.qte_in_transfer);
        } else {
          const product = this.listProducts.find(
            (item) => item.id === transferProduct.id
          );
          if (product) {
            transferProduct.name = product.name;
            transferProduct.price = product.purchase_price;
            transferProduct.qte_in_hub = product?.qte_in_hub ?? 1000000;

            const transferProductsCopy = [...this.transferProducts];
            transferProductsCopy.push(transferProduct);
            this.transferProducts = transferProductsCopy;
          }
        }
        this.transferProductToAdd = {
          qte_in_transfer: 1,
        };
      }
    },

    addProductByEnterKey(event) {
      this.$store
        .dispatch("products/leaf", {
          hub_id: this.from_hub_id,
          sku: event.target.value,
        })
        .then(() => {
          if (this.listProducts.length == 1) {
            var transferProduct = { qte_in_transfer: 1 };
            transferProduct.id = this.listProducts[0].id;
            this.addProduct(transferProduct);
          } else if (this.listProducts.length == 0) {
            this.$store.dispatch("alerts/error", "product not found");
          } else {
            this.$store.dispatch(
              "alerts/error",
              "too many products with this SKU"
            );
          }
          this.search_product_input = "";
        });
    },

    removeProduct(transferProduct) {
      this.transferProducts.splice(
        this.transferProducts
          .map((item) => item.id)
          .indexOf(transferProduct.id),
        1
      );
    },

    calcTotal(transfer_product) {
      return transfer_product?.price * transfer_product?.qte_in_transfer ?? "-";
    },

    getQteInHub(product) {
      if (product.qte_in_hub != null) {
        return product.qte_in_hub;
      }
      return (
        this.listProducts.find((item) => item.id == product.id)?.qte_in_hub ??
        1000
      );
    },
    getHubs() {
      this.$store.dispatch("hubs/list");
    },
  },
};
</script>
